.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.text-muted{
  font-size: 87.5%;
}
.changecolor{
  color: black;
  /* font-family:"Times New Roman", Times, serif; */
  margin-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fbfbfb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
  
}
.App-link-login {
  color: blue;
  text-decoration: none;
  font-size: smaller;
}
.App-headerlogo
{
  height: 60px;
}
.txtcolor{
  color:#007F00;
  margin-left: 30px;
  margin-top: 22px;
}
.colorDarkgray{
  color: darkgray;
  font-size: smaller;
}

.bp-color{
  color: #007F00
}
.footer
{
  position: fixed;
  height: 50px;
  bottom: 0;
  width: 100%;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
